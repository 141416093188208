import React from "react";
import './Footer.css';


function Footer(props) {
    return (
      <div className="footer" >
        Copyright ImPPres {props.year} | Contact us: <a href="mailto:alanfrans@relevantsupport.onmicrosoft.com">alanfrans@relevantsupport.onmicrosoft.com</a>
  
      </div>
    );
  };

  export default Footer;