import React, {Component} from "react";
import Header from './Header.js';
import Footer from './Footer.js';
import './templates.css';
import tData from '../data/templates.json';
import tDataAnnouncement from '../data/templatesannouncements.json';
import tDataChromKey from '../data/templateschromakey.json';
import tDataPresentation from '../data/templatespresentations.json';
import tDataWorship from '../data/templatesworship.json';
import 'bootstrap/dist/css/bootstrap.css';
import TemplateImage from './TemplateImage';
import NavBar from '../NavBar';
import { BasicTable } from '../components/BasicTable';




function templates() {

    return (
        <div className="App">
            <BasicTable />
        </div>
    );
};


export default templates;