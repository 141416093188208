import React, { useMemo } from 'react'
import { useGlobalFilter, useTable, usePagination } from 'react-table'
import TEMPLATE_DATA from '../data/templates.json'
import { COLUMNS } from './columns'
import NavBar from '../NavBar';
import './table.css'
import { GlobalFilter } from './GlobalFilter';

export const BasicTable = () => {

    const columns = useMemo(() => COLUMNS, [])
    const data = useMemo(() => TEMPLATE_DATA, [])

    const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 10 } }, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        state,
        setGlobalFilter,
    } = tableInstance

    const { globalFilter } = state
    return (
            <div className="themedBack">
                <NavBar />
                <p></p>
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                <table {...getTableProps()}>
                    <thead>
                        {
                            headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))
                        }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            // rows.map( row => {
                                page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <div className="pagination">
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous Page
                    </button>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        Next Page
                    </button>
                </div>
            </div>
    )
}
