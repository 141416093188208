import React from "react";
import './Header.css';
import image1 from "../presenterspie.jpg";
function Header(props) {
  return (
    <div>
        <img className="mainImg" src={image1} alt="" />
        <h2 className="mainCenter"> Import, Present and Impress - PowerPoint Add-in 
        <br /> <strong>Easy as 1, 2, 3</strong> </h2>
    </div>
  );
};
export default Header;