import React from "react";
import Header from './Header.js';
import Footer from './Footer.js';

import Main from './Main.js';
import spotlights from '../Spotlight.js';
import {Link, useLocation} from "react-router-dom";


const Whoops404 = () => (
    <>
        <Header name="Import, Present and ImPPres" />
        <h1>[Whoops], there isn't a page here</h1>


        <Footer year={new Date().getFullYear()} /> 
    </>
);

export default Whoops404;