import React from "react";
import Header from './Header.js';
import Footer from './Footer.js';
import Main from './Main.js';
import DownloadBox from './DownloadBox';
import spotlights from '../Spotlight.js';
import NavBar from '../NavBar';
import react, { Component } from 'react';
import { Link, useLocation } from "react-router-dom";
import { PosterImage } from "video-react";
import './Download.css';
// const fs = require('fs');


// var nodemailer = require('nodemailer');
// const { RSA_X931_PADDING } = require('constants');

 var fs = require("fs");



// class user {
//   constructor(id, username, email) {
//     this.id = id;
//     this.name = username;
//     this.email = email;
//   }
// }

// var fileData = "";

// function clickMe(){
//   var name = document.getElementById("name").value;
//   var email = document.getElementById("email").value;
//   // axios.post("./users/addUser/",
//   //   {name,
//   //   email})
//   //   .then(function (response) {
//   //   console.log(response);
//   //   })
//   //   .catch(function (error) {
//   //   console.log(error);
//   //   });
//   // // fetch('http://localhost:8081/addUser',
//   // // {
//   // //   method: 'POST',
//   // //   headers: {'Content-type': 'application/json; charset=utf-8'},
//   // //   body:JSON.stringify(name)}).then(r=>r.json()).then(res=>{
//   // //     if(res){
//   // //       console.log(res);
//   // //     }
//   // // });
//   // Sending and receiving data in JSON format using POST method
// //
// var updateData = "";
// var filePath = __dirname  + "/" + "users.json";
// fileData = fs.readFileSync(filePath, 'utf-8');
// updateData = JSON.parse( fileData );
// var xid = updateData.length + 1;
// console.log("req info: " + name);
// console.log("req info: " + email);
// var thisuser = new user(xid, name, email);
// console.log("user info: " + JSON.stringify(thisuser));
// updateData[xid - 1] = thisuser;
// fileData = updateData;
// console.log( fileData );
// console.log( "FileData is " + JSON.stringify(fileData) );
// fs.writeFile(filePath, JSON.stringify(fileData),   { 
//   encoding: "utf8", 
//   flag: "w", 
//   mode: 0o666 
// }, (err) => {
//   if (err) { throw err; }
//   console.log('Saved!');
// });




// }







class Download extends Component {
  constructor(){
    super();
    this.state = {
      formDisplay: false
    };
    this.toggleDownloadBox = this.toggleDownloadBox.bind(this);
  }


  toggleDownloadBox() {
    var name = document.getElementById("name").value;
    var email = document.getElementById("email").value;
    console.log(name + '\r\n' + email);
    // // alert(name + '\r\n' + email);
    if (name.length > 0 && email.length > 0){

    //   var transporter = nodemailer.createTransport({
    //     host: 'mail.s1273.sureserver.com',
    //     port: 587,
    //     secure: false, // upgrade later with STARTTLS
    //     auth: {
    //       user: 'support@imppres.com',
    //       pass: 'Goodbackup1'
    //     }
    //   });
    //   var mailOptions = {
    //     from: 'support@imppres.com',
    //     to: 'alanfrans@imppres.com',
    //     subject: 'New ImPPres download',
    //     text: 'That was easy!' 
    //   };
    //   // alert('mail options created');
    //   transporter.sendMail({
    //     from: 'support@imppres.com', // sender address
    //     to: 'alanfrans@gmail.com, alanfrans@hotmail.com, alanfrans@yahoo.com', // list of receivers
    //     subject: 'New ImPPres download', // Subject line
    //     text: 'That was easy!', // plain text body
    //     html: '<b>That was easy!</b>', // html body
    //   });
      

    //   // let infomain = await transporter.sendMail(mailOptions, function(error, info){
    //   //   if (error) {
    //   //     console.log(error);
    //   //     alert('error: ' + error);
    //   //   } else {
    //   //     console.log('Email sent: ' + info.response);
    //   //     alert('Email sent: ' + info.response);
    //   //   }
    //   // });
    //   // alert('setting state');
      this.setState({
        formDisplay: !this.state.formDisplay
      });



    }
  }

  render() {
    return (
        
        <div >
        <NavBar />
        <div className="wrapper animated bounceInLeft">
        <h1 className="downloadH1">Download the latest version of ImPPres for PowerPoint</h1>
          <form className="formtext" onSubmit={(event) => (event.preventDefault())} >
            <h3 >Name and Email</h3>
            <input
              type="text"
              placeholder="Enter Name"
              required
              htmlFor="name"
              id="name"
            />
            <br />
            <input
              type="text"
              placeholder="Enter Email Address"
              required
              htmlFor="email"
              id="email"
            />
            <h3>
            {/* <i class="fas fa-arrow-circle-down"></i> */}
            <button className="downloadButton" onClick={this.toggleDownloadBox}>Download</button></h3>
            {/* <h3>
            <button className="downloadButton" onClick={this.clickMe}>email</button></h3> */}
          </form>
          <DownloadBox formDisplay={this.state.formDisplay} />
          </div>
          
        </div>

    );
  }

}

export default Download;