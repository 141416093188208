import React, {Component} from "react";
import Header from './Header.js';
import Footer from './Footer.js';
import './Screensavers.css';
import tData from '../data/screensavers.json';
import 'bootstrap/dist/css/bootstrap.css';
import TemplateImage from './TemplateImage';
import NavBar from '../NavBar';



var prePend = "&lt;tr;&gt;";
var apPend = "&lt;/tr&gt;";
function Screensavers() {
    
    const listItems = tData.map((item, index) =>
    <tr><td className="imageCell"><a href={item.Link}><TemplateImage src={item.Name} /></a></td> 
    <td className="descCell">{item.Description}</td>
    <td className="byCell">{item.Contributor}</td></tr>
     );
    return (
        <div className="themedBack">
            <NavBar />
            <h1 className="templatesH1">Screen Savers</h1>
            <div className="templateBox">
                <table className="table-striped">
                    <thead>
                        <tr className="rows">
                            <th className="header-cell">Click to Download</th>
                            <th className="header-cell-letgo">Description</th>
                            <th className="header-cell-letgo">By</th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {listItems}
                    </tbody>
                </table>
            </div>
            <br /><br /><br /><br />
            <Footer />
        </div>
    );
};


export default Screensavers;