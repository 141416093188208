import React from "react";
import './Easy.css';
import 'bootstrap/dist/css/bootstrap.css';
import image1 from "../childoncomputer.jpg";


function Easy(props) {
    return (
        <div >
            <div>
                <div><h2 className="easyh2">Easy as 1, 2, 3</h2></div>
            </div>
            <div className="grid-container" >
                {/* <div className="grid-itemBlank"></div> */}
                <div className="grid-item1">
                    <div className="easyOL">
                        <div className="text1">1. Load a template </div>
                        <div className="text2">2. Import your document</div>
                        <div className="text3">3. Present</div>
                    </div>
                </div>
                <div className="grid-item2">
                    <img className="easyImage" src={image1} alt="Blue Sky and Clouds" />
                </div>
                {/* <div className="grid-itemBlank"></div> */}
            </div>
        </ div>

    );
};

export default Easy;