import React, {Component} from "react";
import Header from './Header.js';
import Footer from './Footer.js';
import './tips.css';
import tData from '../data/tips.json';
import 'bootstrap/dist/css/bootstrap.css';
import TipImage from './TipImage';
import NavBar from '../NavBar';

function Tips() {
    
    const listItems = tData.map((item, index) =>
    <tr><td className="imageCell"><a href={item.Link} target="yt"><TipImage src={item.Tip} /></a></td> 
    <td className="descCell">{item.Description}</td></tr>
     );
    return (
        <div className="themedBack">
            <NavBar />
            <h1 className="templatesH1">Tips</h1>
            <div className="templateBox">
                <table className="table-striped">
                    <tbody id="myTable">
                        {listItems}
                    </tbody>
                </table>
            </div>
            <br /><br /><br /><br />
            <Footer />
        </ div>
    );
};


export default Tips;

