import './App.css';
import React, { Component } from "react";
import Header from './pages/Header.js';
import Footer from './pages/Footer.js';
import Main from './pages/Main.js';
import spotlights from './Spotlight.js';
import { Route, Routes} from "react-router-dom"; 
import Home from './pages/home';
import About from './pages/about';
import Templates from './pages/templates_basictable';
import FAQs from './pages/faqs';
import TipsNTricks from './pages/tips';
import Download from './pages/Download';

import Whoops404 from './pages/whoops';
import NavBar from './NavBar';
import Screensavers from './pages/Screensavers';
import Templatesannouncments from './pages/templatesannouncements';
import Templateschromakey from './pages/templateschromakey';
import Templatespresentation from './pages/templatespresentation';
import Templatesworship from './pages/templatesworship';
// // import {Home, About, Templates, FAQs, TipsNTricks, Whoops404} from './firstpages';

// // requiring the HTTP interfaces in node
// var http = require('http');
// // create an http server to handle requests and response
// http.createServer(function (req, res) {
// // sending a response header of 200 OK
// res.writeHead(200, {'Content-Type': 'text/html'});
// // print out Hello World
// res.end({App});
// // listen on assigned port
// }).listen(process.env.PORT);

// const templateObjects = spotlights.map((spotlight, i) => ({id: i, title: spotlight}));
// templates.map((template) =>console.log(template));

function SecretComponent(){
  return <h1>this is a secret component</h1>;
}
function OpenComponent(){
  return <h1>this is an open component<br /><br /><br /><br />
  Hello</h1>;
}

class App extends Component {
  render() {
    return (
      
      <div className="App">
        <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/about" element={<About />} exact />
        <Route path="/templates" element={<Templates />} exact/>
        <Route path="/templatesannouncements" element={<Templatesannouncments />} exact/>
        <Route path="/templateschromakey" element={<Templateschromakey />} exact/>
        <Route path="/templatespresentation" element={<Templatespresentation />} exact/>
        <Route path="/templatesworship" element={<Templatesworship />} exact/>
        <Route path="/Screensavers" element={<Screensavers />} exact/>
        <Route path="/faqs" element={<FAQs />} exact />
        <Route path="/tips" element={<TipsNTricks />} exact />
        <Route path="/download" element={<Download />} exact />
        <Route path="*" element={<Whoops404 />} />

       
      </Routes>
      </div>
    );
  } 

}

export default App;
