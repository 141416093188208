import './App.css';
import tData from './data/spotlights.json';
import React, {Component} from "react";
import './pages/templates.css';
import 'bootstrap/dist/css/bootstrap.css';
import TemplateImage from './pages/TemplateImage';


const Spotlight = tData.map((item, index) =>
'{item.Name}',
 );

//  const Spotlight = tData.map((item, index) =>
// <td className="imageCell"><a href={item.Link}><TemplateImage src={item.Name} /></a></td> 
//  );
// const Spotlight = [
//     "animated1.gif",
//     "animated2.gif",
//     "animated3.gif",
//     "animated4.gif",
//     "animated5.gif",
//     "animated6.gif"
//   ];

export default Spotlight;