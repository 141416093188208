import React, {Component} from "react";
import Header from './Header.js';
import Footer from './Footer.js';
import './templates.css';
import tData from '../data/templatespresentations.json';
import 'bootstrap/dist/css/bootstrap.css';
import TemplateImage from './TemplateImage';
import NavBar from '../NavBar';


// var prePend = <tr>;
// var apPend = </tr>;
// var cellPend1 = <td className='imageCell'><a href=;
// var cellPend2 = ><TemplateImage src=;
// var cellPend3 =  /></a>;
// var cellPend4 = <br />;
// var cellPend5 = </td>;
// var prePend = "\u003c\u0074\u0072\u0020\u0063\u006c\u0061\u0073\u0073\u004e\u0061\u006d\u0065\u003d\u0022\u0072\u006f\u0077\u0073\u0022\u003e";
// var apPend = "\u003c\u002f\u0074\u0072\u003e";
// var cellPend1 = "\u003c\u0074\u0064\u0020\u0063\u006c\u0061\u0073\u0073\u004e\u0061\u006d\u0065\u003d\u0027\u0069\u006d\u0061\u0067\u0065\u0043\u0065\u006c\u006c\u0027\u003e\u003c\u0061\u0020\u0068\u0072\u0065\u0066\u003d";
// var cellPend2 = "\u003e\u003c\u0054\u0065\u006d\u0070\u006c\u0061\u0074\u0065\u0049\u006d\u0061\u0067\u0065\u0020\u0073\u0072\u0063\u003d";
// var cellPend3 = "\u002f\u003e\u003c\u002f\u0061\u003e";  
// var cellPend4 = "\u003c\u0062\u0072\u0020\u002f\u003e";
// var cellPend5 = "\u003c\u002f\u0074\u0064\u003e";
var prePend = "&lt;tr;&gt;";
var apPend = "&lt;/tr&gt;";
// var cellPend1 = "&lt;td&nbsp;className=&#39;imageCell&#39;&gt;&lt;a&nbsp;href=";
// var cellPend2 = "&gt;&lt;TemplateImage&nbsp;src=";
// var cellPend3 = "&nbsp;/&gt;&lt;/a&gt;";
// var cellPend4 = "&lt;br&nbsp;/&gt;";
// var cellPend5 = "&lt;/td&gt;";

function filterGrid(filterby){
    var retval = false;
    if (filterby.Category === 'worship'){
        retval = true;
    }
    return retval;

  }


function Templatespresentation() {
    // var fData = tData.filter(function (x) {return x.Category === 'annoucements';});
    const listItems = tData.map((item, index) =>
    <tr className={(item.Category).replace(' ', '')}><td className="imageCell"><a href={item.Link}><TemplateImage src={item.Name} /></a></td> 
    <td className="descCell">{item.Description}</td>
    <td className="byCell">{item.Contributor}</td></tr>
     );
    return (
        <div className="themedBack">
            <NavBar />
            <h1 className="templatesH1">Templates</h1>
            <div className="templateBox">
                <table className="table-striped">
                    <thead>
                        <tr className="rows">
                            <th className="header-cell">Click to Download</th>
                            <th className="header-cell-letgo">Description</th>
                            <th className="header-cell-letgo">By</th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {listItems}
                    </tbody>
                </table>
            </div>
            <br /><br /><br /><br />
            <Footer />
        </div>
    );
};


export default Templatespresentation;