import React, { Component } from 'react';
import './DownloadBox.css';
import 'bootstrap/dist/css/bootstrap.css';



class DownloadBox extends Component {
    render(){
        return (
            <div className={
                (this.props.formDisplay ? 'downloadBoxSection' : 'downloadBoxSectionNoDisplay')
            }>
                Full Setup <a href='https://files.imppres.com/SetupImppres.exe'> link</a>
                <br /> 
                Update 
                <a href='https://files.imppres.com/ImppresSetup.msi'> link</a>
                <br /> 
                Instructions <a href='https://files.imppres.com/GettingStarted.pdf' target='_blank' rel='noopener noreferrer'> link</a>
            </ div>
        );
    }
};

export default DownloadBox;