import React from "react";
import './gumroad';
import './Buy.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom';
import clickMe from './clickMe';


function Buy() {
    return (
<div className="buyBox">
    <div className ="buy-flex-container">
        <div>
            <table>
            <tr><td><h2 className="buyH2">Support ImPPres and Pay What You Want!</h2></td></tr>
            <tr><td><h3 className="buyH3">
            For a limited time, you can purchase a single license for ImPPres at a price you choose, with a suggested donation of $10. Your contribution helps cover the $600 annual cost of maintaining and updating the software. By supporting ImPPres, you enable us to continue providing valuable updates and improvements.
            <br />
            <b>Special Offer:</b> Get an extra license to give to a friend, student, teacher, professor, pastor, or anyone who does presentations.
            <br />Our goal is to sell 60 copies by the end of the year to fund a long-awaited release/update. Thank you for your support!
            <br />(PC Only)
            </h3></td></tr>
            {/* <tr><td><a className="gumroad-button" href="https://gum.co/znkWT" target="_blank">Purchase</a></td></tr> */}
            <script src="https://gumroad.com/js/gumroad.js"></script>
            <tr><td><a class="gumroad-button" href="https://relevant.gumroad.com/l/znkWT">Purchase</a></td></tr> 
            </table>
            </div>
            <div>
            <table>
            <tr><td><h2 className="buyH2">Free Version</h2></td></tr>
            <tr><td><h3 className="buyH3">
            Has basic import capabilities, the rest of the functionality is limited. You get to add your own Pazazz. <br />(PC Only)
            </h3></td></tr>

                    {/* <button onClick={clickMe} className="buyButton">Purchase</button> */}
                    <tr><td><a href="https://relevant.gumroad.com/l/VgSuL"> <button className="buyButton">Download</button></a></td></tr>
                    </table></div>
        
    </ div>
    < br />< br />< br />< br />
</div>


/* <div className="buyBox">
    <div className ="container mx-auto">
        <div className="row">
            <div className="col-sm-6"><h2 className="buyH2">Buy One, Give One</h2></div>
            <div className="col-sm-6"><h2 className="buyH2">Free Version</h2></div>
        </div>
        <div className="row">
            <div className="col-sm-6"> <h3 className="buyH3">
            For a Limited time, purchase a single license for ImPPres for only $29.95 and get an extra license to give to a friend, student, teacher, professor, pastor or anyone that does presentations. <br />(PC Only)
            </h3></div>
            <div className="col-sm-6 my-auto"><h3 className="buyH3">
            Has basic import capabilities, the rest of the functionality is limited. You get to add your own Pazazz. <br />(PC Only)
            </h3></div>
        </div>
        <div className="row mx-auto">

            <div className="col-sm-6">
                <div className="buyH3">
                <a className="gumroad-button" href="https://gum.co/znkWT" target="_blank">Purchase</a>
                    {/* <button onClick={clickMe} className="buyButton">Purchase</button> */
                /*</div>
            </div>
            <div className="col-sm-6">
                <div className="buyH3">
                <Link to="/Download"> <button className="buyButton">Download</button></Link>
                </div>
            </div>
        </div>
        < br />< br />< br />< br />
    </ div>
</div> */
    );
};

export default Buy;