import './NavBar.css';
import React from "react";
import { Link } from 'react-router-dom';
import logo from "./ImppresLogoWhite.png";


const luxStyles = 'luxbar-menu luxbar-menu-right luxbar-menu-material-amber';
const luxHamburger = 'luxbar-hamburger luxbar-hamburger-doublespin';

const NavBar = () => {
    return (
        <>
            {/* <div class="navbar">
                <a href="/">Home</a>
                <a href="/FAQs">FAQs</a>
                <div class="subnav">
                    <button class="subnavbtn">Templates <i class="fa fa-caret-down"></i></button>
                    <div class="subnav-content">
                        <a href="/Templatesannouncements">Annoucements</a>
                        <a href="/Templateschromakey">Chromakeys</a>
                        <a href="/Templatespresentations">Presentations</a>
                        <a href="/Templatesworship">Worship</a>
                    </div>
                </div>
                <a href="/Screensavers">Screensavers</a>
                <a href="https://download.importpresent.com">Download</a>
                <a href="/Tips">Tips</a>
                <a href="/About">About</a>
            </div> */}
            {/* <nav> */}
                <header id="luxbar" className="luxbar-static">
                    <input type="checkbox" className="luxbar-checkbox" id="luxbar-checkbox" />
                    <div className="luxbar-menu luxbar-menu-right luxbar-menu-material-powerpoint">
                        <ul className="luxbar-navigation">
                            <li className="luxbar-header">
                                <img src={logo} height={65} className="compLogo" alt="company logo" />
                                <div className="luxbar-brand">Import Present for PowerPoint</div>
                                <label className="luxbar-hamburger luxbar-hamburger-doublespin"
                                    id="luxbar-hamburger" for="luxbar-checkbox"> <span></span> </label>
                            </li>
                            <li class="luxbar-item">
                                <a href="/">Home</a>
                            </li>
                            <li class="luxbar-item">
                                <a href="/FAQs">FAQs</a>
                            </li>
                            <li class="luxbar-item">
                                <a href="/Templates">Templates</a> 
                            </li>
                            <li class="luxbar-item">
                                <a  href="/Screensavers">Screensavers</a>
                            </li>                            
                            <li class="luxbar-item">
                                <a  href="https://relevant.gumroad.com/l/VgSuL">Download</a>
                            </li>
                            <li class="luxbar-item">
                                <a href="/Tips">Tips</a>
                            </li>
                            <li class="luxbar-item">
                                <a href="/about">About</a>
                            </li>
                        </ul>
                    </div>
                </header>
            {/* </nav> */}
        </>
    );
}

export default NavBar;