import React, {useState} from "react";
import $ from "jquery";
import Header from './Header.js';
import Footer from './Footer.js';
import 'bootstrap/dist/css/bootstrap.css';
import { Button, Collapse, Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import './faqs.css';
import freeVersionImage from "../images/FreeVersion.png";
import fullVersionImage from "../images/FullVersion.png";
import tData from '../data/FAQs.json';
import NavBar from '../NavBar';

const createTemplate = "";

function FAQs() {
   const [open, setOpen] = useState(false);
   const listItems = tData.map((item, index) =>
     <Card>
     <Card.Header>
       <Accordion.Toggle as={Button} variant="link" eventKey={index + 1}>
       {item.Question}
       </Accordion.Toggle>
     </Card.Header>
     <Accordion.Collapse eventKey={index + 1}>
       <Card.Body>
         <p>
       {item.Answer}
       </p>
       </Card.Body>
     </Accordion.Collapse>
   </Card>
    );
    return (
    <>
        <NavBar />
        <h1 className="faqsH1">FAQs</h1>
        <h2 className="faqsH2">
        <div className="container">


<Accordion>
      {listItems}
</Accordion>
</div>
</h2>
<div className="faqBox "> <table className="table"> <tbody> <tr className="rows"> <th className="header-cell">Feature</th> 
 <th className="header-cell">Free Version</th> 
 <th className="header-cell">Full Version</th> 
</tr> 
 <tr className="rows"> <td className="cell">Import Word Documents</td> 
 <td className="cell">✔</td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Import Text from Clipboard</td> 
 <td className="cell">✔</td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Create Customized Templates</td> 
 <td className="cell">✔</td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Load Customized Templates</td> 
 <td className="cell">✔</td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Include a Dynamic Header</td> 
 <td className="cell">✔</td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Include a Static Header</td> 
 <td className="cell">✔</td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Headers for Outlines</td> 
 <td className="cell"></td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Include a Footer</td> 
 <td className="cell"></td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Copy Font Styling From Word Document</td> 
 <td className="cell"></td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Copy Pictures From Word Document</td> 
 <td className="cell"></td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Override Font Resizing on Outlines</td> 
 <td className="cell"></td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Optionally Animate text by Letter</td> 
 <td className="cell"></td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Set the Animation Speed</td> 
 <td className="cell"></td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Create by Transfer</td> 
 <td className="cell">✔</td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Create by DropDown Styles</td> 
 <td className="cell"></td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Create by Overhead Style</td> 
 <td className="cell"></td> 
 <td className="cell">✔</td> 
</tr> 
 <tr className="rows"> <td className="cell">Create by Scroller Style (Teleprompt)</td> 
 <td className="cell"></td> 
 <td className="cell">✔</td> 
</tr> 
</tbody> 
</table> 
</div>
<br />
<br />
<br />
<br />
      <Footer year={new Date().getFullYear()} /> 
    </>
    );
}


export default FAQs;

