import React from "react";
import './Highlights.css';
import 'bootstrap/dist/css/bootstrap.css';
import tData from '../data/spotlights.json';
import TemplateImage from './TemplateImage';
// {props.templates.map((template) => (
//     < div className = "carousel-item" >
//     <img src="../{template.title}" alt="../{template.title}" />
//         <div class="carousel-caption d-none d-md-block">
//             <h5>../{template.title}</h5>
//             <p>by the ImPPres team</p>
//         </div>
//     </div>)};
 const Spotlights = tData.map((item, index) =>
<td><a href={item.Link} ><TemplateImage src={(item.Name).substring(0,item.Name.lastIndexOf("_"))} /></a></td>
 );
function Offline() {
    return (
        <div className="highlightsDiv" >
            <h2 className="highlightsh2">While this site is in transition, you may experience broken links.  More updates coming soon!</h2>
            <div className="highlightsSection">
                <div className="highlight-flex-container">
                </div>
            </div>
        </div>
    );
};

export default Offline;