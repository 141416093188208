import React from 'react';
import { Player, ControlBar } from 'video-react';
import video1 from "../Example.mp4";
import "../../node_modules/video-react/dist/video-react.css"; // import css
import 'bootstrap/dist/css/bootstrap.css';
import './Example.css';



function Example(props) {

    return (
        <div className="exampleSection">
            <div className="example-flex-container">
            <Player autoPlay muted playsInline>
                <source src={video1} />
            </Player>
            </div>
        </ div>
    );
};

export default Example;