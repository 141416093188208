import React from "react";
import Header from './Header.js';
import NavBar from '../NavBar';
import Footer from './Footer.js';
import './about.css'
import Main from './Main.js';
import spotlights from '../Spotlight.js';
import {Link, useLocation} from "react-router-dom";


const About = () => (
    <>
        <NavBar />
        <h1 className="aboutH1">About</h1>
        <h2 className="aboutH2">
        Impres is published by the Relevant LLC Development team. This effort came because there was no easy way to import larger documents into PowerPoint for practical presentation. So we were forced to copy and paste text from our document into each slide. This is great for some, but for others – not so great.  ImPPres is simply the fastest way to do it.
        </ h2>
<br /><br />
<br /><br />
    </>
);

export default About;
