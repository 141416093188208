import React from "react";
import TemplateImage from '../Pages/TemplateImage.js';
export const COLUMNS = [
    {
        id: 'Category',
        Header: 'Category',
        accessor: (row) => row.Category,
        // use a select filter for this column
        filterVariant: 'select',
        // specify the options for the select filter
        filterSelectOptions: [
            { value: 'Announcements', label: 'Announcements' },
            { value: 'ChromaKeys', label: 'ChromaKeys' },
            { value: 'Presentation', label: 'Presentation' },
            { value: 'Worship', label: 'Worship' },
        ],
    },
    {
        id: 'Image',
        Header: 'Example',
        accessor: 'Image',
        Cell: (props) => (
            <TemplateImage src={`${props.value}`} />
        ),
    },
    {
        id: 'Name',
        Header: 'Name',
        accessor: 'Description',
    },
    {
        id: 'Link',
        Header: 'Download',
        accessor: 'Link',
        Cell: (props) => (
            <a href={`${props.value + '.potx'}`}>
                Download
            </a>
        ),
    }
]
{/* <img src={`${props.value}`}></img> */ }

// {
//     id: 'Category',
//     Header: ({ column }) => (
//         <div>
//           Category
//           <input
//             value={column.filterValue || ""}
//             onChange={(e) => column.setFilter(e.target.value)}
//           />
//         </div>
//       ),
//     accessor: (row) => row.Category,
//     filter: (rows, filterValue) =>
//     rows.filter((row) => {
//       // check if row.Category is defined and has a value
//       if (row.Category) {
//         // check if row.Category is an array or a string
//         if (Array.isArray(row.Category) || typeof row.Category === "string") {
//           // call includes on row.Category
//           return row.Category.includes(filterValue);
//         }
//       }
//       // return false if row.Category is undefined or not an array or a string
//       return false;
//     }),
// },
// {
//     id: 'Image',
//     Header: 'Example',
//     accessor: 'Image',
//     Cell: (props) => (
//         <TemplateImage src={`${props.value}`} />
//       ),
// },
// {
//     Header: 'Name',
//     accessor: 'Description',
// },
// {
//     Header: 'Download',
//     accessor: 'Link',
//     Cell: (props) => (
//         <a href={`${props.value}`}>
//           Download
//         </a>
//       ),
// }
