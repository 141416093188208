import React from "react";
import Header from './Header.js';
import Footer from './Footer.js';
import Main from './Main.js';
import Buy from './Buy.js';
import './home.css';
import spotlights from '../Spotlight.js';
import {Link, useLocation} from "react-router-dom";
import NavBar from '../NavBar';



    //const templateObjects = spotlights.map((spotlight, i) => ({id: i, title: spotlight}));
    const Home = () => (
        <>
            <NavBar />
            <Main />
            <Buy />
            <Footer year={new Date().getFullYear()} /> 
        </ >
    );

export default Home;
