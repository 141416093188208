import React from 'react'

class TipImage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        imgSrc: `http://images.imppres.com/${props.src}.jpg`,
        imgJpg: `http://images.imppres.com/${props.src}.jpg`
    };
  }

  render() {
    return (
      <div>
        <img src={this.state.imgSrc}/>
      </div>
    );
  }

}


export default TipImage;