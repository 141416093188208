import React from 'react'

class TemplateImage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        imgSrc: `https://images.imppres.com/${props.src}.jpg`,
        imgGif: `https://images.imppres.com/${props.src}.gif`,
        imgJpg: `https://images.imppres.com/${props.src}.jpg`,
        imgAlt: `${props.alt}`
    };
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  handleMouseOver() {
    this.setState({
      imgSrc: this.state.imgGif
    });
  }

  handleMouseOut() {
    this.setState({
      imgSrc: this.state.imgJpg
    });
  }

  render() {
    return (
      <div classname="centerdiv">
        <img classname="tdimage" onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src={this.state.imgSrc} alt={this.state.imgAlt} />
      </div>
    );
  }

}

TemplateImage.propTypes = {
}

TemplateImage.defaultProps = {
}

export default TemplateImage;