import React from "react";
import './Main.css';
import Highlights from './Highlights';
import ScreensaverHighlights from './ScreensaverHighlights';
import Header from './Header';
import Easy from './Easy';
import Example from './Example';
import Buy from './Buy';
import spotlights from '../Spotlight.js';
import Offline from './Offline';

const templateObjects = spotlights.map((spotlight, i) => ({id: i, title: spotlight}));

function Main() {
    return (
        <div  >
            <Header />
            <Offline />
            <Highlights />
            <ScreensaverHighlights />
            <Easy />
            <Example />
        </div>
    );
};

export default Main;